import React from 'react'
import { motion, Variants } from 'framer-motion'


const ArticleTemp = ({header, descText, children}) => {
      
      const FadeIn: Variants = {
        offscreen: {
          x: -200,
          opacity: 0,
        },
        onscreen: {
          x:0,
          opacity: 1,
          transition: {
            type: "spring",
            bounce: 0.2,
            duration: 0.6,
          },
         
        }
      }

      const leftFadeIn: Variants = {
        offscreen: {
          x: -200,
          opacity: 0,
        },
        onscreen: {
          x:0,
          opacity: 1,
          transition: {
            type: "spring",
            bounce: 0.2,
            duration: 1,
          },
         
        }
      }

    return (
        <section className='text-gray-500'>  
        <motion.div
          initial='offscreen'
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.05 }}
          className='pt-4'
        >
          <motion.h1 variants={FadeIn} className='text-center text-xl font-black tracking-widest md:text-2xl border-b-2 border-gray-500 p-2 max-w-screen-lg mx-auto'>
            {header} 
          </motion.h1>

            <motion.div variants={leftFadeIn} className='m-4 text-sm max-w-4xl lg:mx-auto'>
              <p className='text-lg whitespace-pre-wrap'>{descText}</p>
              { children }
            </motion.div>
        </motion.div>   
      </section>
    )
}

export default ArticleTemp