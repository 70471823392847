import { faUpload } from '@fortawesome/free-solid-svg-icons';
import React, { MouseEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

const ContactForm = ({ register, getValues, setValue, fileName, setFileName, handleNext, handleSubmit, errors }) => {

  const confirmStep = () => {
    handleNext()
  }

  const fileRemove = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      setFileName('')
      setValue('files', null)
  }

  const onSubmit = () => confirmStep()

  return (
    <div className='bg-white p-2 md:p-10 md:w-3/4 lg:p-15 lg:w-1/2 mx-auto rounded-md text-md'>
        <h1 className='text-center mb-4 text-lg'>送信フォーム</h1>
        <form name="contact" onSubmit={handleSubmit(onSubmit)}>
            <div className='flex items-center mb-5'>
                <label htmlFor="name" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                    <FontAwesomeIcon icon={faAsterisk} className='inline-block w-20 font-bold text-red-600 align-center mr-1'size='xs'/>
                    お名前
                </label>
                <div className='flex-1'>
                    <input type="text" id='name' name='name' placeholder='お名前' className={`w-full py-2  text-gray-600 placeholder-gray-400 outline-none border-b ${errors.name ? 'border-red-600 focus:border focus:border-red-600' : 'border-gray-400 focus:border-indigo-400 '}`} {...register("name", { required: true })} />
                    {errors.name && errors.name.type === "required" && <p className='flex-1 text-xs text-red-600'>お名前は必須です</p>}
                </div>
            </div>
            <div className='flex items-center mb-5'>
                <label htmlFor="kana" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                    <FontAwesomeIcon icon={faAsterisk} className='inline-block w-20 font-bold text-red-600 align-center mr-1'size='xs'/>
                    フリガナ
                </label>
                <div className='flex-1'>
                    <input type="text" id='kana' name='kana' placeholder='フリガナ' className={`w-full py-2 border-b text-gray-600 placeholder-gray-400 outline-none ${errors.kana ? 'border-red-600 focus:border focus:border-red-600' : 'border-gray-400 focus:border-indigo-400'}`} {...register("kana", { required: true })} />
                    {errors.kana && errors.kana.type === "required" && <p className='flex-1 text-xs text-red-600 '>フリガナは必須です</p>}
                </div>
            </div>
            <div className='flex items-center mb-5'>
                <label htmlFor="mail" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                    <FontAwesomeIcon icon={faAsterisk} className='inline-block w-20 font-bold text-red-600 align-center mr-1'size='xs'/>
                    E-mail
                </label>
                <div className='flex-1'>
                    <input type="mail" id='mail' name='mail' placeholder='メールアドレス' className={`w-full py-2 border-b text-gray-600 placeholder-gray-400 outline-none ${errors.mail ? 'boreder-red-600 focus:border focus:border-red-600' : 'border-gray-400 focus:border-indigo-400'}`} {...register("mail", { required: true, pattern:  /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/ })} />
                    {errors.mail && errors.mail.type === "required" && <p className='flex-1 text-xs text-red-600 '>E-mailは必須です</p>}
                    {errors.mail && errors.mail.type === "pattern" && <p className='flex-1 text-xs text-red-600 '>E-mailの形式が不正です</p>}
                </div>
            </div>
            <div className='flex items-center mb-5'>
                <label htmlFor="addr" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                    ご住所
                </label>
                <div className='flex-1'>
                    <input  type="text" id='addr' name='addr' placeholder='ご住所' className='w-full py-2 border-b border-gray-400 focus:border-indigo-400 text-gray-600 placeholder-gray-400 outline-none' {...register("addr")} />
                </div>
            </div>
            <div className='flex items-center mb-5'>
                <label htmlFor="tel" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                    <FontAwesomeIcon icon={faAsterisk} className='inline-block w-20 font-bold text-red-600 align-center mr-1'size='xs'/>
                    電話番号
                </label>
                <div className='flex-1'>
                    <input  type="tel" id='tel' name='tel' placeholder='09012345678' maxLength={11} className={`w-full py-2 border-b text-gray-600 placeholder-gray-400 outline-none ${errors.tel ? 'border-red-600 focus:border focus:border-red-600' : 'border-gray-400 focus:border-indigo-400'}`} {...register("tel", { required: true, pattern: /^0\d{9,10}$/ })} />
                    {errors.tel && errors.tel.type === "required" && <p className='flex-1 text-xs text-red-600 '>電話番号は必須です</p>}
                    {errors.tel && errors.tel.type === "pattern" && <p className='flex-1 text-xs text-red-600 '>電話番号は半角数字ハイフン無しで入力してください。</p>}
                </div>
            </div>
            <div className='flex items-center mb-5'>
                <label htmlFor="fax" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                    FAX番号
                </label>
                <div className='flex-1'>
                    <input  type="tel" id='fax' name='fax' placeholder='0662227745' maxLength={11} className={`w-full py-2 border-b text-gray-600 placeholder-gray-400 outline-none ${errors.fax ? 'border-red-600 focus:border focus:border-red-600' : 'border-gray-400 focus:border-indigo-400'}`} {...register("fax",{require: false, pattern: /^0\d{9,10}$/ })} />
                    {errors.fax && errors.fax.type === "pattern" && <p className='flex-1 text-xs text-red-600 '>FAX番号は半角数字ハイフン無しで入力してください。</p>}
                </div>
            </div>
            
            <div className='flex items-center mb-5'>
                <label htmlFor="content" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                    <FontAwesomeIcon icon={faAsterisk} className='inline-block w-20 font-bold text-red-600 align-center mr-1'size='xs'/>
                    内容
                </label>
                <div className='flex-1'>
                    <textarea  name="content" id="content" placeholder='ご相談内容・折り返しのご連絡方法、お電話をご希望される場合は、ご連絡のつきやすい曜日、時間帯なども併せてご記入ください。また、御見積を希望される場合は、相続登記対象不動産の評価額や、対象不動産の登記状況等も必要となります。' rows={10} className={`w-full py-2 border text-gray-600 placeholder-gray-400 outline-none ${errors.content ? 'border-red-600 focus:border-red-600' : 'border-gray-400 focus:border-indigo-400'}`} {...register("content", { required: true })} />
                    {errors.content && errors.content.type === "required" && <p className='flex-1 text-xs text-red-600 '>内容は必須です</p>}
                </div>
            </div>
            <div className='flex items-center mb-1'>
                <label htmlFor="file" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                    参考資料<br/>
                    (画像/PDF)
                </label>
                
                    <label htmlFor='file' className='text-indigo-600 border-2 border-gray-400 rounded-xl py-2 px-4 bg-emerald-200 shadow-lg hover:cursor-pointer hover:border-indigo-400'>
                        <FontAwesomeIcon icon={faUpload} className='mr-2 text-indigo-600' />
                        <input hidden multiple type="file" id='file' name='file' {...register("files")} accept='image/*, application/pdf' />
                        {fileName ? "変更" : "ファイル選択"}
                    </label>
                    
                    {fileName && (
                        <button onClick={fileRemove} className='border-2 border-gray-400 rounded-xl py-2 px-4 bg-emerald-200 shadow-lg hover:cursor-pointer hover:border-indigo-400 mx-2'>
                            クリア
                        </button>
                    )}
                   
                
            </div>
            <div className='flex items-center mb-5'>
                <label htmlFor="fileName" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                    ファイル名
                </label>
                <p id='fileName' className='flex-1 py-2 border-b border-gray-400 focus:border-indigo-400 text-gray-600 placeholder-gray-400 outline-none' >{fileName ? fileName : 'ファイルなし'}</p>
            </div>
            <div className='flex justify-center mb-5'>
                <button type='submit' className='py-2 px-10 border-2 rounded-xl shadow-lg bg-emerald-200  border-gray-400 focus:border-indigo-400 focus:bg-opacity-70 text-gray-600 placeholder-gray-400 outline-none'>送信内容確認</button>
            </div>
            
        </form>
    </div>
  )
};

export default ContactForm