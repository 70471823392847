import ContactForm from '@/components/blocks/contactForm';
import ContactFormConfirm from '@/components/blocks/contactFormConfirm';
import React from 'react';

const useContactStep = ({
    register,
    getValues,
    setValue,
    fileName,
    setFileName,
    watchShowName, 
    watchShowKana, 
    watchShowMail,
    watchShowAddr, 
    watchShowTel, 
    watchShowFax, 
    watchShowContent,
    handleNext,
    handleBack,
    reset,
    setIsOpen,
    stepReset,
    handleSubmit,
    errors,
    onSubmit
}) => {

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <ContactForm 
                            register={register} 
                            getValues={getValues}
                            setValue={setValue}
                            fileName={fileName}
                            setFileName={setFileName}
                            handleNext={handleNext}
                            handleSubmit={handleSubmit}
                            errors={errors}
                        />
            case 1:
                return <ContactFormConfirm 
                            watchShowName={watchShowName}
                            watchShowKana={watchShowKana}
                            watchShowMail={watchShowMail}
                            watchShowAddr={watchShowAddr}
                            watchShowTel={watchShowTel}
                            watchShowFax={watchShowFax}
                            watchShowContent={watchShowContent}
                            fileName={fileName}
                            handleBack={handleBack}
                            getValues={getValues}
                            reset={reset}
                            setIsOpen={setIsOpen}
                            stepReset={stepReset}
                            handleSubmit={handleSubmit}
                            onSubmit={onSubmit}
                        />
            default:
                throw new Error('step error')
        }
    };
    return {
        getStepContent
    };
}

export default useContactStep
