import Snackbar from '@/components/blocks/snackbar';
import Layout from '@/components/Layout';
import ArticleTemp from '@/components/modules/articleTemp';
import useContactStep from '@/hooks/useContactStep';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useUploadAndSend from '@/hooks/useUploadAndSend';
import SEO from '@/components/seo';
import ExternalLinkIcon from '@/components/atoms/externalLinkIcon';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import SimpleExternalLink from '@/components/atoms/simpleExternalLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Values = {
    name: string;
    kana: string;
    mail: string;
    addr: string;
    tel: string;
    fax: string;
    content: string;
    files: FileList | null
}

const Contact = () => {
    const { register, getValues, setValue, watch, reset, handleSubmit, formState: { errors }} = useForm<Values>({
        defaultValues: {
            name: "",
            kana: "",
            mail: "",
            addr: "",
            tel: "",
            fax: "",
            content: "",
            files: null
        }
    })

    const watchShowName = watch("name")
    const watchShowKana = watch("kana")
    const watchShowMail = watch("mail")
    const watchShowAddr = watch("addr")
    const watchShowTel = watch("tel")
    const watchShowFax = watch("fax")
    const watchShowContent = watch("content")
    const watchShowFiles = watch("files")

    const [fileName, setFileName] = useState('')
    const [activeStep, setActiveStep] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const getFileName = (watchShowFiles: FileList) => {
        let str = ''
        if (watchShowFiles) {
            for(let i = 0; i < watchShowFiles.length; i++) {
                 str += watchShowFiles[i].name + '   '
            }
            setFileName(str)
        }
    }

    const steps = ["送信フォーム", '確認フォーム']

    const handleNext = () => setActiveStep(activeStep + 1)

    const handleBack = () => setActiveStep(activeStep - 1)

    const stepReset = () => {
        setActiveStep(0)
        setFileName('')
    }

    const closeSnack = () => {
        setTimeout(() => {
            if(isOpen) {
                setIsOpen(false)
            }
        }, 10000);
    }

    const successForm = () => {
        stepReset()
        setIsOpen(true);
        setTimeout(() => reset(), 1000);
    }

    const errorForm = () => {
        console.log("error")
    }

    const { slackSend } = useUploadAndSend(fileName, errorForm)

    const onSubmit = (data: Values) => {
        try {
            slackSend(data)
            successForm()
        } catch {
            () => errorForm()
        }
    }

    const { getStepContent } = useContactStep({
        register,
        getValues,
        setValue,
        fileName,
        setFileName,
        watchShowName, 
        watchShowKana, 
        watchShowMail,
        watchShowAddr, 
        watchShowTel, 
        watchShowFax, 
        watchShowContent,
        handleNext,
        handleBack,
        reset,
        setIsOpen,
        stepReset,
        handleSubmit,
        errors,
        onSubmit
    })

    useEffect(() => getFileName(watchShowFiles), [getValues().files])
    useEffect(() => closeSnack(), [isOpen])

    return (
        <Layout>
            <SEO title={`お問い合わせ`} description={`相続のことで少しでもお悩み事がありましたら、みつわ合同事務所へお気軽にお問い合わせください。`} />
            <ArticleTemp header={`お問い合わせ`}　descText={`相続登記のご相談は下記よりお願いいたします。また、下記のSNSからお問い合わせいただくことも可能です。`}>
                <div className='w-full text-lg'>
                    <p>
                    お問い合わせの際は、弊所の
                    <a href='https://www.mitsuwa-off.jp/privacy-policy/' target='_blank' rel='noopener noreferrer' className='text-blue-500 underline hover:text-red-500 hover:bg-pink-300'>「個人情報保護方針」</a>をご覧いただき、ご同意いただきますようお願いいたします。
                    なお、送信いただきました方については、個人情報保護方針に同意いただいたものとさせていただきます。
                    </p>    
                </div>
                <div className='bg-sky-300 py-10 px-2 md:px-4 my-4 min-h-screen rounded-md'>

                    {activeStep >= steps.length ? (
                        <>
                        {/* setTimeout 数秒でstepResetする */}
                            <p>送信しました。</p>
                            <button onClick={stepReset}>
                                お問い合わせ画面へ
                            </button>
                        </>
                    ) : (
                        <>
                            { getStepContent(activeStep) }
                        </>
                    )}

                </div>
            </ArticleTemp>
            <Snackbar isOpen={isOpen}/> 
        </Layout>
    );
};

export default Contact;
