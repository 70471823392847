import React from 'react';
import fetch from 'node-fetch'
import { graphql, useStaticQuery } from 'gatsby';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import firebaseApp from 'gatsby-plugin-firebase-v9.0'

const useUploadAndSend = (fileName, errorForm) => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    slackUrl
                    storageURL
                    bucket
                }
            }
        }
    `)

    const upload = (file: File | null) => {
        if(file) {
            const st = getStorage(firebaseApp, site.siteMetadata.bucket)
            const storageRef = ref(st, file.name)
            uploadBytes(storageRef, file).then(snapshot => console.log(snapshot))
            .catch(() => errorForm())
        }
    }

    type Values = {
        name: string;
        kana: string;
        mail: string;
        addr: string;
        tel: string;
        fax: string;
        content: string;
        files: FileList | null
    }

    const slackSend = (data:Values) => {
        const payload = {
            text: `
相続特設ページよりお問い合わせ >>>

お名前: ${data.name}
カナ: ${data.kana}
e-mail: ${data.mail}
住所: ${data.addr}
TEL: ${data.tel}
FAX: ${data.fax}
内容: ${data.content}
ファイル名: ${fileName} 
ファイル場所: ${site.siteMetadata.storageURL}
`
        }
        if(data.files) {
            for(let i = 0; i < data.files.length; i++) {
                upload(data.files[i])      
            }
        } 

       fetch(site.siteMetadata.slackUrl, {
            method: "POST",
            body: JSON.stringify(payload),
        }).catch(() =>  {
            throw Error('送信エラー')
        })
    }
  return {
      slackSend
  }
};

export default useUploadAndSend;
