import { motion } from 'framer-motion';
import React from 'react';

const Snackbar = ({isOpen}) => {
  return (
      <div className={isOpen ? '' : 'hidden'}>
        <motion.div initial={{opacity: 0}} whileInView={{ opacity: [0, 0, 1, 1, 0.8, 0.4, 0] }} transition={{duration: 8}} className="bg-green-100 border-t border-b border-green-500 text-green-700 px-4 py-3 fixed bottom-20 w-full mx-auto" role="alert">
            <p className="font-bold text-center">送信しました。</p>
            <p className="text-sm">お問い合わせの内容によっては回答に時間を要する場合があります。また、回答させていただく上で、追加でご質問させていただくことがありますので、ご了承ください。</p>
        </motion.div>
      </div>
)};

export default Snackbar;
