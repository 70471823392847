import React, { MouseEvent } from 'react';

const ContactFormConfirm = ({watchShowName, watchShowKana, watchShowMail, watchShowAddr, watchShowTel, watchShowFax, watchShowContent, fileName, handleBack, getValues, reset, setIsOpen, stepReset, handleSubmit, onSubmit}) => {

    const backStep = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        handleBack()
    }

    return (
        <div className='bg-white p-2 md:p-10 md:w-3/4 lg:p-15 lg:w-1/2 mx-auto rounded-md text-md'>
            <h1 className='text-center mb-4 text-lg'>確認フォーム</h1>
            <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)} >
                <div className='flex items-center mb-5'>
                    <label htmlFor="name" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                        お名前
                    </label>
                    <p className='flex-1 py-2 px-1 bg-emerald-100 border-b border-gray-400 focus:border-indigo-400 text-gray-600 placeholder-gray-400 outline-none'>{watchShowName}</p>
                </div>
                <div className='flex items-center mb-5'>
                    <label htmlFor="kana" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                        フリガナ
                    </label>
                    <p className='flex-1 py-2 px-1 bg-emerald-100 border-b border-gray-400 focus:border-indigo-400 text-gray-600 placeholder-gray-400 outline-none'>{watchShowKana}</p>
                </div>
                <div className='flex items-center mb-5'>
                    <label htmlFor="mail" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                        E-mail
                    </label>
                    <p className='flex-1 py-2 px-1 bg-emerald-100 border-b border-gray-400 focus:border-indigo-400 text-gray-600 placeholder-gray-400 outline-none'>{watchShowMail}</p>
                </div>
                <div className='flex items-center mb-5'>
                    <label htmlFor="addr" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                        ご住所
                    </label>
                    <p className='flex-1 py-2 px-1 bg-emerald-100 border-b border-gray-400 focus:border-indigo-400 text-gray-600 placeholder-gray-400 outline-none'>{watchShowAddr}</p>
                </div>
                <div className='flex items-center mb-5'>
                    <label htmlFor="tel" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                        お電話番号
                    </label>
                    <p className='flex-1 py-2 px-1 bg-emerald-100 border-b border-gray-400 focus:border-indigo-400 text-gray-600 placeholder-gray-400 outline-none'>{watchShowTel}</p>
                </div>
                <div className='flex items-center mb-5'>
                    <label htmlFor="fax" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                        FAX番号
                    </label>
                    <p className='flex-1 py-2 px-1 bg-emerald-100 border-b border-gray-400 focus:border-indigo-400 text-gray-600 placeholder-gray-400 outline-none'>{watchShowFax}</p>
                </div>
                
                <div className='flex items-center mb-5'>
                    <label htmlFor="content" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                        ご相談内容
                    </label>
                    <textarea readOnly name="content" id="content" value={watchShowContent} placeholder='ご相談内容・折り返しのご連絡方法、お電話をご希望される場合は、ご連絡のつきやすい曜日、時間帯なども併せてご記入ください。また、御見積を希望される場合は、相続登記対象不動産の評価額や、対象不動産の登記状況等も必要となります。' rows={10} className='flex-1 py-2 px-1 select-none bg-emerald-100 border border-gray-400 focus:border-indigo-400 text-gray-600 placeholder-gray-400 outline-none' />
                </div>
                <div className='flex items-center mb-5'>
                    <label htmlFor="file" className='inline-block w-20 mr-6 text-right font-bold text-gray-600'>
                        参考資料
                    </label>
                    <p id='file' className='flex-1 py-2 bg-emerald-100 border-b border-gray-400 focus:border-indigo-400 text-gray-600 placeholder-gray-400 outline-none' >{fileName ? fileName : 'ファイルなし'}</p>
                </div>
                <div className='flex justify-center gap-5 mb-5'>
                    <button onClick={backStep} className='py-2 px-10 border-2 rounded-xl shadow-lg bg-rose-200  border-gray-400 focus:border-indigo-400 text-gray-600 placeholder-gray-400'>戻る</button>
                    <button type='submit' className='py-2 px-10 border-2 rounded-xl shadow-lg bg-emerald-200  border-gray-400 focus:border-indigo-400 text-gray-600 placeholder-gray-400'>送信</button>
                </div>
            </form>
        </div>
    );
};

export default ContactFormConfirm